import { useLocale } from '@zep/hooks';
import { BoltFillIcon, BookCoverFillIcon, ChartPieFillIcon, MapFillIcon, PersonStackFillIcon, SparklingFillIcon } from '@zep/icons';
import { Gray600 } from '@zep/ui/ui-tokens/color';
import { Button } from '@zep/ui_v3';
import { cn } from '@zep/utils';
import { useTranslation } from 'next-i18next';
import { usePlanInquiryLink } from '../../hook';
import { CardBottomContentItem, PricingCard, PricingCardBottomLayout, PricingCardTopLayout } from './PricingCard';

//25.02.14
//한국에만 가격이 적힌 pricing card 문구 적용
const PaidPricingCardTopText = () => {
  const {
    t
  } = useTranslation();
  const {
    locale
  } = useLocale();
  if (locale === 'ko') return <>
        <div className={'text-heading-lg-extrabold text-white'}>
          {'￦'}
          <span className={'text-heading-xl-extrabold'}>{'50,000'}</span>
          <span className={'text-body-md-bold'}>{'/월,인당'}</span>
        </div>
        <div className={'text-body-md-medium text-inverse'}>
          {'3명 이상, 최소 3개월부터'}
        </div>
      </>;
  return <div className={'text-heading-lg-extrabold text-white'} data-sentry-component="PaidPricingCardTopText" data-sentry-source-file="PaidPricingCard.tsx">
      {t('home.pricing.IntroduceInquiry', '도입 문의')}
    </div>;
};
const PaidPricingCardTop = () => {
  const {
    t
  } = useTranslation();
  return <PricingCardTopLayout className="rounded-t-[22px]" style={{
    background: 'linear-gradient(99deg, #A49BFF 0%, #18D0B4 100%)'
  }} data-sentry-element="PricingCardTopLayout" data-sentry-component="PaidPricingCardTop" data-sentry-source-file="PaidPricingCard.tsx">
      {/* FIXME: usePortableDevice 이용해서 구간 통일하는 것이 좋을 듯 하다 */}
      <img className={cn('absolute bottom-0 right-0 max-h-[84px]', 'min-[976px]:max-h-[108px]')} alt={'bg'} src={'/assets/pricing/pricing_card_bg.png'} />
      <div className={'flex flex-col gap-3xs'}>
        <div className={'text-heading-lg-extrabold text-inverse'}>PRO PLAN</div>
        <div className={'text-body-lg-medium'}>
          <span className={'text-inverse'}>
            {t('home.pricing.min3GroupPlan', '선생님 3명부터 이용 가능한 단체 요금제')}
          </span>
        </div>
      </div>
      <div className={'flex flex-col gap-3xs'}>
        <PaidPricingCardTopText data-sentry-element="PaidPricingCardTopText" data-sentry-source-file="PaidPricingCard.tsx" />
      </div>
    </PricingCardTopLayout>;
};
const PaidPricingCardBottom = () => {
  const {
    t
  } = useTranslation();
  const {
    locale
  } = useLocale();
  return <PricingCardBottomLayout data-sentry-element="PricingCardBottomLayout" data-sentry-component="PaidPricingCardBottom" data-sentry-source-file="PaidPricingCard.tsx">
      <h1 className={'text-body-md font-extrabold'}>
        <span className={'text-strong'}>
          {t('home.pricing.quizProAllFunction', '퀴즈 프로 요금제의 모든 기능')}
        </span>
      </h1>
      <div className={'flex flex-col gap-sm'}>
        <CardBottomContentItem key={'21'} icon={<MapFillIcon width={20} height={20} fill={Gray600} />} data-sentry-element="CardBottomContentItem" data-sentry-source-file="PaidPricingCard.tsx">
          {t('proModal.allQuizMap', '모든 퀴즈 맵')}
        </CardBottomContentItem>
        <CardBottomContentItem key={'22'} icon={<SparklingFillIcon width={20} height={20} fill={Gray600} />} data-sentry-element="CardBottomContentItem" data-sentry-source-file="PaidPricingCard.tsx">
          {t('home.pricing.infinityMakeAiQuiz', '무제한 AI 문제 만들기')}
        </CardBottomContentItem>
        <CardBottomContentItem icon={<BookCoverFillIcon width={20} height={20} fill={'#7A7B89'} />} data-sentry-element="CardBottomContentItem" data-sentry-source-file="PaidPricingCard.tsx">
          {t('lmsBtn', '학습 리포트')}
        </CardBottomContentItem>
        <CardBottomContentItem icon={<ChartPieFillIcon width={20} height={20} fill={'#7A7B89'} />} data-sentry-element="CardBottomContentItem" data-sentry-source-file="PaidPricingCard.tsx">
          {t('proModal.questionStats', '문제별 통계')}
        </CardBottomContentItem>
        <CardBottomContentItem icon={<PersonStackFillIcon width={20} height={20} fill={'#7A7B89'} />} data-sentry-element="CardBottomContentItem" data-sentry-source-file="PaidPricingCard.tsx">
          {t('proModal.studentStatus', '학생별 학습 현황')}
        </CardBottomContentItem>
      </div>
      {locale === 'ko' && <div className={'text-body-md-medium text-alternative'}>
          {'지금 문의하고 기간별 할인을 받아보세요'}
        </div>}
    </PricingCardBottomLayout>;
};
export const PaidPricingCard = () => {
  const {
    t
  } = useTranslation();
  const goLink = usePlanInquiryLink();
  return <PricingCard style={{
    border: '2px solid transparent',
    backgroundImage: 'linear-gradient(#fff, #fff), linear-gradient(-45deg, #18D0B4, #A49BFF)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box'
  }} top={<PaidPricingCardTop />} bottom={<PaidPricingCardBottom />} ActionButton={<Button style={{
    background: 'linear-gradient(90deg, #8478FF 0%, #3DA9FF 100%)'
  }} size={'lg'} className={'w-full'} onClick={goLink} leftSlot={<BoltFillIcon fill={'#FFF'} />}>
          {t('home.pricing.nowInquiry', '지금 문의하기')}
        </Button>} data-sentry-element="PricingCard" data-sentry-component="PaidPricingCard" data-sentry-source-file="PaidPricingCard.tsx"></PricingCard>;
};